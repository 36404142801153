import styles from './mobileAppButtons.module.scss';
import { getImageUrl, baseImageConfig } from 'lib/cloudinary';
import AnalyticsService from 'lib/analytics/mixpanel/mixpanel-browser';
import events from 'lib/analytics/mixpanel/events';
import { useCallback } from 'react';
import { usePathname } from 'next/navigation';
import { appLinks } from '../../data/appPageData';

type MobileAppProps = {
  className: string;
};

const MobileAppButtons = ({ className }: MobileAppProps) => {
  const asPath = usePathname();
  const sendMixpanelClick = useCallback(
    ({ href, label }: { href: string; label: string }) => {
      AnalyticsService.track(events.LINK_CLICK, {
        location: 'Mobile App Landing Page',
        label: label,
        href: href,
        pathname: asPath || '/mobile-app',
      });
    },
    [asPath],
  );

  return (
    <div className={styles.mediaLinks}>
      {appLinks.map((link, index) => {
        const { title, href, cloudinaryId, altText } = link;
        return (
          <a
            key={index}
            href={href}
            target="_blank"
            title={title}
            data-text={title}
            onClick={() =>
              sendMixpanelClick({
                label: title,
                href: href,
              })
            }
          >
            <picture>
              <img
                className={className}
                src={getImageUrl(cloudinaryId, {
                  ...baseImageConfig,
                  height: 53,
                })}
                alt={altText}
              />
            </picture>
          </a>
        );
      })}
    </div>
  );
};
export default MobileAppButtons;
