import React from 'react';
import styles from './mobileAppHero.module.scss';
import HeroImage from './partials/HeroImage/HeroImage';
import HeroMessaging from './partials/HeroMessaging/HeroMessaging';
import AppLinks from './partials/AppLinks/AppLinks';
import HeroTestimonials from './partials/HeroTestimonials/HeroTestimonials';
import { messagingList, testimonials } from '../data/appPageData';

const cloudinaryId = '/App%20Landing%20Page/Mock_Frame_01_mimdjb.png';
const MobileAppHero = () => {
  return (
    <section>
      <div className={styles.heroWrapper}>
        <div className={styles.eclipseDecorator}></div>
        <div className={styles.gridWrapper}>
          <div className={styles.intro}>
            <HeroMessaging messagingList={messagingList} />
          </div>
          <div className={styles.image}>
            <HeroImage cloudinaryId={cloudinaryId} />
          </div>
          <div className={styles.links}>
            <AppLinks />
          </div>
        </div>
      </div>
      <div className={styles.testimonialWrapper}>
        <div className={styles.testimonial}>
          <HeroTestimonials testimonials={testimonials} />
        </div>
      </div>
    </section>
  );
};

export default MobileAppHero;
