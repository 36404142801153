import {
  NodeProp,
  SectionProps,
  TestimonialCarouselProps,
  MetaDataProps,
  AppLinksProps,
} from './types';

export const appLinks: AppLinksProps[] = [
  {
    title: 'Get the app on Apple App Store',
    href: 'https://apps.apple.com/us/app/americas-test-kitchen/id1365223384?ls=1',
    cloudinaryId: 'Super Mise/app-store-button',
    altText: 'Apple App Store',
  },
  {
    title: 'Get the app on Android Play Store',
    href: 'https://play.google.com/store/apps/details?id=com.americastestkitchen.groceryapp',
    cloudinaryId: 'Super Mise/android-play-store-button',
    altText: 'Android Play Store',
  },
];

export const metaData: MetaDataProps = {
  title: "Mobile App for Recipes & Cooking Tips | America's Test Kitchen",
  description:
    "Collect, rate, and personalize your favorite recipes from America's Test Kitchen in our mobile app! Build your grocery list, read reviews from our kitchen equipment experts, and watch full episodes anywhere, anytime.",
};

export const messagingList: NodeProp[] = [
  {
    copy: <span>Build Your Own Recipe Collections</span>,
  },
  {
    copy: <span>Track Recipes with “I Cooked This” Tag</span>,
  },
  {
    copy: <span>Get Tools for Quick & Easy Shopping</span>,
  },
  {
    copy: <span>Add Personal Notes to Recipes</span>,
  },
  {
    copy: (
      <span>
        <strong>New!</strong> Add On ATK Classes
      </span>
    ),
  },
];

export const testimonials: NodeProp[] = [
  {
    copy: (
      <span>
        Best recipes! I’ve been using ATK for years. Used to hate cooking till I
        found them. Now everything turns out great. <br />– Gari W.
      </span>
    ),
  },
  {
    copy: 'Fantastic recipes, and an app designed by people who understand what it’s like to cook from a recipe using a phone or tablet. – Kevin M.',
  },
  {
    copy: 'I can’t tell you how many times I’ve checked the ATK app while shopping to find the best ingredient, the best equipment, the best of everything (recipes too of course!) – Lisa B.',
  },
];
export const testimonialCarousel: TestimonialCarouselProps[] = [
  {
    quote: 'Recipes that always, always work.',
    citation: <em>- The New York Times</em>,
  },
  {
    quote:
      'Trust is the reason why America’s Test Kitchen’s cooking shows reach more than four million viewers.',
    citation: <em>- CBS News Sunday Morning</em>,
  },
  {
    quote: 'Far and away the best cooking program on television today.',
    citation: '- Slate.com',
  },
  {
    quote: 'The two best magazines dealing with home cooking.',
    citation: <em>- The Wall Street Journal</em>,
  },
];
export const sections: SectionProps[] = [
  {
    image: {
      cloudinaryId: '/App%20Landing%20Page/Mock_Frame_02_iwbawc.png',
      altText: 'Build Your Own Collection',
    },
    headline: 'Build Your Own Recipe Collections',
    sectionBody: [
      {
        bodyType: 'text',
        bodyCopy:
          'Want to organize your growing cache of great recipes? You can do that with collections! Every time you favorite a recipe, you can quickly add it to an existing collection or create a new collection. Recipes marked as “I Cooked This” will automatically be added to a collection so you can easily find and make them again.',
      },
    ],
  },
  {
    image: {
      cloudinaryId: '/App%20Landing%20Page/Mock_Frame_03_f3mwwd.png',
      altText: 'Recipe Notes',
    },
    headline: (
      <>
        Customized Personal <span>Recipe Notes</span>
      </>
    ),
    sectionBody: [
      {
        bodyType: 'text',
        bodyCopy:
          'Substituted an ingredient that worked really well? Paired a new favorite main dish with a great side you want to remember? Leave a note to your future self in a dedicated section for personal notes.',
      },
    ],
  },
  {
    image: {
      cloudinaryId: '/App%20Landing%20Page/Mock_Frame_04.png',
      altText: 'Shopping List',
    },
    headline: 'Personalized Shopping Lists',
    sectionBody: [
      {
        bodyType: 'text',
        bodyCopy:
          'When prepping for a recipe (or two or three!) you can easily add selected items, or all ingredients, to your shopping list. You can toggle your Shopping list to view ingredients “By Category,” for easy shopping, or “By Recipe.” You can also add other ingredients to your list for an all-in-one shopping list.',
      },
    ],
  },
  {
    image: {
      cloudinaryId: '/App%20Landing%20Page/Mock_Frame_05_maddii.png',
      altText: 'ATK Classes',
    },
    headline: 'New Offering: ATK Classes',
    sectionBody: [
      {
        bodyType: 'text',
        bodyCopy:
          'Master innovative test kitchen techniques with focused (and fun!) learning based on your interests.',
      },
      {
        bodyType: 'list',
        bodyList: [
          'Build your skills and refine your cooking intuition',
          'Use “Cook Along” mode to get detailed guidance at every step',
          'Hundreds of videos and up-close photos guarantee success',
          'Get your cooking questions answered directly from your ATK instructor',
          'Access classes for all skill levels',
        ],
      },
    ],
  },
];
