import styles from './heroTestimonials.module.scss';
import { Text } from '@americastestkitchen/mise/components/Typography/Text/index';
import { UtilityIcon } from '@americastestkitchen/mise/components/Icons/UtilityIcon/index';
import { Heading } from '@americastestkitchen/mise/components/Typography/Heading/Heading';
import { NodeProp } from '../../../data/types';

const HeroTestimonials = ({ testimonials }: { testimonials: NodeProp[] }) => {
  return (
    <div className={styles.testimonialGrid}>
      <Heading
        as="h2"
        variantOverride="h4"
        className={styles.testimonialHeader}
      >
        What Our Members Are Saying
      </Heading>
      <div className={styles.testimonialCardsWrapper}>
        {testimonials.map((testimonial, index) => (
          <div className={styles.body} key={`testimonial-${index}`}>
            <div className={styles.ratings}>
              {Array.from({ length: 5 }).map((_, index) => (
                <UtilityIcon
                  className={styles.star}
                  key={index}
                  type="starFull"
                />
              ))}
            </div>
            <div className={styles.bodyContent}>
              <Text as="p" variant="bodySecondaryLong">
                {testimonial.copy}
              </Text>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeroTestimonials;
