import React, { useEffect, useState } from 'react';

const useIntersectionV2 = (
  ref: React.RefObject<HTMLElement> | null,
  options: IntersectionObserverInit,
) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const checkEntry = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(checkEntry, options);
    const currentRef = ref?.current;
    if (currentRef) observer.observe(currentRef);
    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [options, ref]);

  return isVisible;
};

export default useIntersectionV2;
