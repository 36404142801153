import styles from './featureSection.module.scss';
import { Heading } from '@americastestkitchen/mise/components/Typography/Heading/Heading';
import { Text } from '@americastestkitchen/mise/components/Typography/Text/index';
import { getImageUrl, baseImageConfig } from 'lib/cloudinary';
import clsx from 'clsx';
import { SectionProps } from '../data/types';

const imgConfig = {
  mobile: {
    ...baseImageConfig,
    width: 200,
  },
  tablet: {
    ...baseImageConfig,
    width: 400,
  },
  desktop: {
    ...baseImageConfig,
    width: 500,
  },
};

const FeatureSection = ({
  section,
  isEven,
}: {
  section: SectionProps;
  isEven: boolean;
}) => {
  const {
    image: { cloudinaryId, altText },
    headline,
    sectionBody,
  } = section;
  return (
    <section
      className={clsx(styles.background, {
        [styles.roseTint]: isEven,
        [styles.roseBase]: !isEven,
      })}
    >
      <div
        className={clsx(styles.wrapper, {
          [styles.wrapperBodyRight]: isEven,
          [styles.wrapperBodyLeft]: !isEven,
        })}
      >
        <div className={styles.messagingSection}>
          <div
            className={clsx(styles.body, {
              [styles.right]: isEven,
              [styles.left]: !isEven,
            })}
          >
            <Heading as="h2" className={styles.header}>
              {headline}
            </Heading>
            {sectionBody.map((body, index) => {
              const { bodyType } = body;
              if (bodyType === 'text' && body.bodyCopy) {
                return (
                  <Text
                    className={styles.paragraph}
                    key={`text-${index}`}
                    variant="bodyPrimaryLong"
                    as="p"
                  >
                    {body.bodyCopy}
                  </Text>
                );
              }
              if (bodyType === 'list' && body.bodyList) {
                return (
                  <ul className={styles.listWrapper} key={`list-${index}`}>
                    {body.bodyList.map((listItem, index) => (
                      <li className={styles.listItem} key={`item-${index}`}>
                        <Text variant="bodyPrimaryLong" as="p">
                          {listItem}
                        </Text>
                      </li>
                    ))}
                  </ul>
                );
              }
            })}
          </div>
        </div>
        <div className={styles.imageWrapper}>
          <picture>
            <source
              srcSet={getImageUrl(cloudinaryId, imgConfig.desktop)}
              media="(min-width: 1136px)"
            />
            <source
              srcSet={getImageUrl(cloudinaryId, imgConfig.tablet)}
              media="(min-width:768px)"
            />
            <img
              className={styles.heroImage}
              src={getImageUrl(cloudinaryId, imgConfig.desktop)}
              alt={altText}
              loading="lazy"
            />
          </picture>
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
