import styles from './testimonialCarousel.module.scss';
import { Fragment, useState } from 'react';
import clsx from 'clsx';
import TestimonialBody from './partials/TestimonialBody';
import { testimonialCarousel } from '../data/appPageData';

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const setTestimonial = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className={styles.relativePos}>
      <div className={styles.testimonialsWrapper}>
        {testimonialCarousel.map((testimonial, index) => (
          <TestimonialBody
            key={index}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            index={index}
            testimonial={testimonial}
          />
        ))}
        <div className={styles.activeItems}>
          <div className={styles.focusActiveItems}>
            {testimonialCarousel.map((_, index) => (
              <Fragment key={index}>
                <button
                  aria-label={`Display testimonial ${index + 1}`}
                  onClick={() => setTestimonial(index)}
                  className={clsx(
                    `${styles.breadButton} ${styles.breadcrumbDesktop}`,
                    {
                      [`${styles.active}`]: index == currentIndex,
                    },
                  )}
                ></button>
                <div
                  aria-label={`Display testimonial ${index + 1}`}
                  className={clsx(
                    `${styles.breadButton} ${styles.breadcrumbMobile}`,
                    {
                      [`${styles.active}`]: index == currentIndex,
                    },
                  )}
                ></div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
