import React from 'react';
import styles from './heroMessaging.module.scss';
import { Heading } from '@americastestkitchen/mise/components/Typography/Heading/Heading';
import { Text } from '@americastestkitchen/mise/components/Typography/Text/index';
import { UtilityIcon } from '@americastestkitchen/mise/components/Icons/UtilityIcon/UtilityIcon';
import { NodeProp } from '../../../data/types';

const HeroMessaging = ({ messagingList }: { messagingList: NodeProp[] }) => {
  return (
    <div className={styles.wrapper}>
      <Heading as="h1" className={styles.header}>
        Your Mobile Cooking Companion
      </Heading>
      <Text as="p" className={styles.subHeader} variant="subheaderMedium">
        Download the 5-star-rated ATK app for these helpful features:
      </Text>
      <ul>
        {messagingList.map((message, index) => (
          <li className={styles.listItem} key={`list-item-${index}`}>
            <span>
              <UtilityIcon className={styles.icon} type="check" />
              <Text variant="bodyPrimaryLong" as="span">
                {message.copy}
              </Text>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HeroMessaging;
