import styles from './appLinks.module.scss';
import { Heading } from '@americastestkitchen/mise/components/Typography/Heading/Heading';
import MobileAppButtons from 'components/mobile-app/sharedPartials/MobileAppButtons/MobileAppButtons';
import QrCode from 'components/mobile-app/sharedPartials/QrCode/QrCode';

const AppLinks = () => {
  return (
    <div className={styles.qrCodeWrapper}>
      <QrCode className={`${styles.qrCode}`} />
      <div>
        <Heading as="h2" variantOverride="h4" className={styles.header}>
          Download the app today
        </Heading>
        <MobileAppButtons className={`${styles.logos}`} />
      </div>
    </div>
  );
};

export default AppLinks;
