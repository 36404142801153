import styles from './testimonialBody.module.scss';
import { useEffect, useRef } from 'react';
import { Text } from '@americastestkitchen/mise/components/Typography/Text/index';
import cx from 'classnames';
import useIntersectionV2 from 'lib/hooks/useIntersectionv2';
import { TestimonialBodyProps } from 'components/mobile-app/data/types';

const TestimonialBody: React.FC<TestimonialBodyProps> = ({
  currentIndex,
  testimonial,
  index,
  setCurrentIndex,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const testimonialVisible = useIntersectionV2(ref, {
    threshold: 0.75,
  });

  useEffect(() => {
    if (testimonialVisible) {
      setCurrentIndex(index);
    }
  }, [testimonialVisible, setCurrentIndex, index]);

  const { quote, citation } = testimonial;
  return (
    <div
      ref={ref}
      className={cx(styles.testimonialWrapper, {
        [styles.hidden]: index != currentIndex,
      })}
    >
      <div className={styles.testimonialContent}>
        <Text className={styles.quote} as="p" variant="displaySmall">
          “{quote}”
        </Text>
        <Text className={styles.citation} as="p" variant="displaySmall">
          {citation}
        </Text>
      </div>
    </div>
  );
};

export default TestimonialBody;
