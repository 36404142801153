import { getImageUrl } from 'lib/cloudinary';

const QrCode = ({ className }: { className: string }) => {
  return (
    <div className={className}>
      <img
        alt="Scan our QR code"
        src={getImageUrl(
          '/App%20Landing%20Page/App_Page_-_CTA__Download_the_App_QR-code',
        )}
        height={157}
        width={157}
      />
    </div>
  );
};
export default QrCode;
