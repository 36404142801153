import React, { useEffect, useState, SyntheticEvent } from 'react';
import { baseImageConfig, getImageUrl } from 'lib/cloudinary';
import useAppContext from 'lib/hooks/useAppContext';
import analytics from 'lib/analytics';
import SiteHeader from 'components/modules/SiteHeader';
import styles from './errorPage.module.scss';
import { RoundedCornerContainer } from '@americastestkitchen/mise/components/RoundedCornerContainer/index';
import { Typography } from '@americastestkitchen/mise/components/Typography/index';
import { HighlightCta } from '@americastestkitchen/mise/components/CTAs/HighlightCta/index';
import classNames from 'classnames';
import { Svg } from '@americastestkitchen/ui/svg/Svg';

const cx = classNames.bind(styles);

const documentTypes: string[] = [
  'equipment_reviews',
  'taste_tests',
  'how_tos',
  'buying-guides',
  'skills',
  'collections',
  'guides',
  'articles',
  'recipes',
  'episode',
  'videos',
  'authors',
];
const getDocumentData = (pathname: string) => {
  const splitPath = pathname.substring(1).toLowerCase().split('/');
  const firstItem = splitPath[0].toLowerCase();
  const index =
    firstItem === 'cookscountry' || firstItem === 'cooksillustrated' ? 1 : 0;
  const documentType = documentTypes.includes(splitPath[index])
    ? splitPath[index]
    : 'N/A';
  const documentId = documentType !== 'N/A' ? splitPath[index + 1] : 'N/A';
  return { documentType, documentId };
};

const ErrorPage = ({ not404 }: { not404?: boolean }) => {
  const [query, setQuery] = useState('');
  const { domainSiteKey, url, queryParams, pathname } = useAppContext();
  const { documentType, documentId } = getDocumentData(pathname);

  const formClassNames = cx(styles.label, {
    [styles.typed]: query.length > 0,
  });
  useEffect(() => {
    const errorPageData = {
      location: 'Milk Jug',
      document_id: documentId,
      documentType: documentType,
      status_code: 404,
    };
    // Skip 404 analytics tracking on 500 errors
    if (!not404) {
      analytics.track('ERROR_PAGE', errorPageData, {
        transport: 'sendBeacon',
      });
    }
  }, [documentType, documentId, not404]);

  const cloudinaryId =
    'https://res.cloudinary.com/hksqkdlah/image/upload/v1688590410/milk_zi4mrx.png';
  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const href = `/search?q=${query}`;
    return (document.location.href = href);
  };

  return (
    <div className={styles.mainPageWrapper}>
      <SiteHeader
        meta={{
          title: 'Error',
        }}
        canonical={url}
        pathname={pathname}
        queryParams={queryParams}
        reducedTracking={false}
        siteKey={domainSiteKey}
        url={url}
      />
      <div className={styles.errorWrapper}>
        <RoundedCornerContainer>
          <div className={styles.errorContent}>
            <div className={styles.errorBody}>
              <Typography type="moret" size="xlg" elementTag="h1">
                Oops, That Link Has Expired
              </Typography>
              <Typography type="open" elementTag="p">
                Sorry about that. It&apos;s probably not your fault. Try a
                search instead:
              </Typography>
              <form className={styles.search} onSubmit={handleSubmit}>
                <label htmlFor="error-search-input" className={formClassNames}>
                  <Typography weight="bold" size="lg" type="open">
                    Search
                  </Typography>
                </label>
                <input
                  id="error-search-input"
                  className={styles.query}
                  type="text"
                  value={query}
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                />
                <button
                  className={styles.submit}
                  type="submit"
                  aria-label="Search button"
                >
                  <Svg className={styles.spyglassIcon} type="spyglass" />
                </button>
              </form>
              <div className="links">
                <Typography type="open" weight="bold">
                  Let&apos;s get you cooking again:
                </Typography>
                <HighlightCta
                  as="a"
                  href="/"
                  type="primary"
                  typography={{ type: 'open', weight: 'normal' }}
                >
                  America&apos;s Test Kitchen Homepage
                </HighlightCta>
                <HighlightCta
                  as="a"
                  href="https://support.americastestkitchen.com"
                  type="primary"
                  typography={{ type: 'open', weight: 'normal' }}
                >
                  America&apos;s Test Kitchen Help Center
                </HighlightCta>
              </div>
            </div>
            <div className={styles.errorMilk}>
              <picture>
                <source
                  srcSet={getImageUrl(cloudinaryId, {
                    ...baseImageConfig,
                    width: 204,
                    height: 267,
                  })}
                  media="(min-width:768px)"
                />
                <img
                  className={styles.image}
                  src={getImageUrl(cloudinaryId, {
                    ...baseImageConfig,
                    width: 102,
                    height: 133,
                  })}
                  alt="milk jug"
                  loading="lazy"
                />
              </picture>
            </div>
          </div>
        </RoundedCornerContainer>
      </div>
    </div>
  );
};

export default ErrorPage;
