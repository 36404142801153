import React from 'react';
import styles from './heroImage.module.scss';
import { getImageUrl, baseImageConfig } from 'lib/cloudinary';

const imgConfig = {
  mobile: {
    ...baseImageConfig,
    height: 500,
  },
  tablet: {
    ...baseImageConfig,
    height: 450,
  },
  desktop: {
    ...baseImageConfig,
    height: 600,
  },
};

const HeroImage = ({ cloudinaryId }: { cloudinaryId: string }) => {
  return (
    <picture>
      <source
        srcSet={getImageUrl(cloudinaryId, imgConfig.desktop)}
        media="(min-width: 1136px)"
      />
      <source
        srcSet={getImageUrl(cloudinaryId, imgConfig.tablet)}
        media="(min-width:768px)"
      />
      <img
        className={styles.heroImage}
        src={getImageUrl(cloudinaryId, imgConfig.mobile)}
        alt="Mobile App on Phone"
        loading="lazy"
      />
    </picture>
  );
};

export default HeroImage;
