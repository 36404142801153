import styles from './appLinkSection.module.scss';
import { Heading } from '@americastestkitchen/mise/components/Typography/Heading/index';
import MobileAppButtons from '../sharedPartials/MobileAppButtons/MobileAppButtons';
import QrCode from '../sharedPartials/QrCode/QrCode';

const AppLinkSection = () => {
  return (
    <section className={styles.background}>
      <div className={styles.wrapper}>
        <div className={styles.bodyWrapper}>
          <Heading as="h4" className={styles.header}>
            Download the app today
          </Heading>
          <QrCode className={`${styles.qrCode}`} />
          <MobileAppButtons className={`${styles.logos}`} />
        </div>
      </div>
    </section>
  );
};

export default AppLinkSection;
